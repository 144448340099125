import React from "react"
import Article from "../components/Article"
import i18nHOC from "../components/i18nHOC"
import Paragraph from "../components/Paragraph"
import ImageWithTitle from "../components/ImageWithTitle"
import { graphql } from "gatsby"
import SinglePortraitImageWithTitle from "../components/SinglePortraitImageWithTitle"

function cherryPlum({ data, t }) {
  return <Article coverImage={{ image: data.coverImage, title: t("items.cherryPlum.coverCap")}} title={t("items.cherryPlum.title")}>
    <Paragraph>{t("items.cherryPlum.p1")}</Paragraph>
    <ImageWithTitle fluid={data.cherryPlum1.childImageSharp.fluid} title={t("items.cherryPlum.cap1")}/>
    <SinglePortraitImageWithTitle fluid={data.merged.childImageSharp.fluid}
                                  title={t("items.cherryPlum.cap2")} />

    <ImageWithTitle fluid={data.cherryPlum4.childImageSharp.fluid}
                    title={t("items.cherryPlum.cap3")}/>

    <ImageWithTitle fluid={data.cherryPlum5.childImageSharp.fluid}
                    title={t("items.cherryPlum.cap4")}
                    style={{padding: '0 15%'}}/>
    <ImageWithTitle fluid={data.cherryPlum6.childImageSharp.fluid}
                    title={t("items.cherryPlum.cap5")}
                    style={{padding: '0 15%'}} />
  </Article>
}

export default i18nHOC(cherryPlum);

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "cherry-plum-jam/DSC_2770 (1).JPG" }) {
      ...CoverImage
    },
    cherryPlum1: file(relativePath: { eq: "cherry-plum-jam/IMG_9851 (1).jpg" }) {
      ...FluidArticleImage
    },
    
    merged: file(relativePath: { eq: "cherry-plum-jam/gathering-merged.jpg" }) {
      ...FluidArticleImage
    },
    
    cherryPlum4: file(relativePath: { eq: "cherry-plum-jam/DSC_2823.JPG" }) {
      ...FluidArticleImage
    },
    cherryPlum5: file(relativePath: { eq: "cherry-plum-jam/DSC_2838.JPG" }) {
      ...FluidArticleImage
    },
    cherryPlum6: file(relativePath: { eq: "cherry-plum-jam/DSC_2670.JPG" }) {
      ...FluidArticleImage
    }
  }
`